import React, { useContext, useEffect, useState } from 'react';

// Firebase Import
import firebase from '../../config/Firebase';

// Assets imports
import GoBackIcon from '../../assets/imgs/goback_icon.svg';
import CardScoreCalculator from '../../component/CardScoreCalculator';
import AwardIcon from '../../assets/imgs/award_icon.JPG';
import MaxPontuationIcon from '../../assets/imgs/max_pontuation_icon.svg';
import SeturPontuationIcon from '../../assets/imgs/setur_pontuation_icon.svg';
import { firstSection, secondSection, thirdSection, fourthSection } from '../../utils/spRankingManager';

// eslint-disable-next-line import/no-cycle
import { MyContext } from '../../component/SidebarAdmin';

// CSS import
import '../../assets/css/RankingComponents/RankingCalculatorSP.css';

// Components imports
import Loading from '../../component/Loading';
import TitleEditor from '../../component/FormComponents/TitleEditor';
import SpinButton from '../../component/SpinButton';

export default function RankingCalculatorSP({ cityManager, setRankingPopup }) {
  const { city } = useContext(MyContext);
  const [dataRankSum, setDataSum] = useState({
    flux: {
      curr: undefined,
      max: undefined,
    },
    attr: {
      curr: undefined,
      max: undefined,
    },
    services: {
      curr: undefined,
      max: undefined,
    },
    acessibility: {
      curr: undefined,
      max: undefined,
    },
  });
  const [attracScore, setAttracScore] = useState();
  const [cityScore, setCityScore] = useState();
  const [demandScore, setDemandScore] = useState();
  const [comturScore, setComturScore] = useState();
  const [totalScore, setTotalScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);
  const [pointSetur, setPointSetur] = useState();
  const [citySelected, setCitySelected] = useState(cityManager || city);
  const [cityData, setCityData] = useState();
  const [idCity, setIdCity] = useState();

  async function handleInfo(dataCity) {
    const attrData = await firstSection(dataCity);
    const cityData = await secondSection(dataCity);
    const demandData = await thirdSection(dataCity);
    const comturData = await fourthSection(dataCity);

    let sum = 0;
    let max = 0;
    const dataSum = {
      attr: {
        curr: 0,
        max: 0,
      },
      city: {
        curr: 0,
        max: 0,
      },
      demand: {
        curr: 0,
        max: 0,
      },
      comtur: {
        curr: 0,
        max: 0,
      },
    };

    attrData.map((attr) => {
      max += (attr.maxScore || 0);
      sum += (attr.currScore || 0);

      dataSum['attr'].curr += (attr.currScore || 0);
      dataSum['attr'].max += (attr.maxScore || 0);
    });

    cityData.map((city) => {
      max += (city.maxScore || 0);
      sum += (city.currScore || 0);

      dataSum['city'].curr += (city.currScore || 0);
      dataSum['city'].max += (city.maxScore || 0);
    });

    demandData.map((dema) => {
      max += (dema.maxScore || 0);
      sum += (dema.currScore || 0);

      dataSum['demand'].curr += (dema.currScore || 0);
      dataSum['demand'].max +=( dema.maxScore || 0);
    });

    comturData.map((comt) => {
      max += (comt.maxScore || 0);
      sum += (comt.currScore || 0);

      dataSum['comtur'].curr += (comt.currScore || 0);
      dataSum['comtur'].max += (comt.maxScore || 0);
    });

    setAttracScore(attrData);
    setCityScore(cityData);
    setDemandScore(demandData);
    setComturScore(comturData);

    // City's Points
    setDataSum(dataSum);
    setTotalScore(sum);
    setMaxScore(max);

    return { dataSum, sum };
  }

  function calculateSectionScore(key, fillScore) {
    let curr = 0;
    let max = 0;

    fillScore.map((scr) => {
      curr += scr.currScore;
    });

    const newDataSum = {
      ...dataRankSum,
      [key]: {
        curr: curr,
        max: dataRankSum[key].max,
      }
    };

    Object.keys(newDataSum).map((key) => {
      max += newDataSum[key].curr;
    })
    
    setDataSum(newDataSum);
    setTotalScore(max);
  }
  

  async function handlePontuationSetur() {
    const docs = await firebase.firestore().collection('Cities').where('name', '==', citySelected).get();

    setPointSetur(docs.docs[0].data().PontosSetur);
  }

  async function handleCheckCityRank() {
    const docCity = await firebase.firestore().collection('Cities').where('name', '==', citySelected).get();

    const dataCity = docCity.docs[0].data();
    const idCity = docCity.docs[0].id;

    setCityData(dataCity);
    setIdCity(idCity);
    await handleInfo(dataCity, idCity);
  }

  function handleInitStats() {
    setAttracScore();
    setCityScore();
    setDemandScore();
    setComturScore();
    setTotalScore();
    setMaxScore();
    setPointSetur();
    setDataSum({
      attr: {
        curr: undefined,
        max: undefined,
      },
      city: {
        curr: undefined,
        max: undefined,
      },
      demand: {
        curr: undefined,
        max: undefined,
      },
      comtur: {
        curr: undefined,
        max: undefined,
      },
    });

    handleCheckCityRank();
  }

  async function updateValueCity(val, field, type) {
    let newCity;
    if (type === true) {
      newCity = { ...cityData, [field]: val };
      await firebase.firestore().collection('Cities').doc(idCity).set(newCity);
    } else {
      newCity = { ...cityData, [field]: val === 'Sim' ? true : false };
      await firebase.firestore().collection('Cities').doc(idCity).set(newCity);
    }

    setCityData(newCity);
  }

  useEffect(() => {
    if (!cityManager)
      setCitySelected(city);
  }, [city]);

  useEffect(() => {
    if (citySelected)
      handleInitStats();
    handlePontuationSetur();
  }, [citySelected]);

  const script = async () => {
    const docs = await firebase.firestore().collection("Cities").where("Estado", "==", "São Paulo").get();
    const cities = docs.docs;
    for (let i = 0; i < cities.length; i++) {
      const city = cities[i].data();
      if (city.Estado === "São Paulo") {
        const { dataSum, sum } = await handleInfo(city);

        await firebase.firestore().collection("Cities").doc(cities[i].id).update({
          pontosInventario: dataSum.attr.curr,
          pontosCidade: dataSum.city.curr,
          pontosComtur: dataSum.comtur.curr,
          pontosEstudoDemanda: dataSum.demand.curr,
          pontosTotal: sum,
        });
      }
    }

    console.log("feito");
  }

  if (!citySelected)
    return <Loading />;

  return (
    <div className='RankingCalculator'>
      {
        cityManager ? (
          <div>
            <button onClick={() => setRankingPopup(false)} className='btn_goback_ranking_cities'>
              <img src={GoBackIcon} alt='Icone indicando para voltar a pagina anterior' className='img_btn_goback_ranking_cities'/>
              <span>Voltar</span>
            </button>
          </div>
        ) : null
      }
      <button onClick={() => script()}>Teste</button>
      <TitleEditor type={'Ranqueamento Paulista - ' + citySelected} subTitle={'Análise de diagnóstico da competitividade turistica do seu município'} />
      <div className='div_cards_pontuation'>
        <div className="div_card_curr_pontuation div_card_max_pontuation">
          <div className="div_align_card_max">
            <div className="div_img_card_max">
              <img src={AwardIcon} />
            </div>
            <div className="div_info_card_max">
              <span>PONTUAÇÃO TOTAL</span>
              <h2 className='title_score_calculator'>{totalScore === undefined ? <SpinButton /> : totalScore % 1 > 0 ? totalScore.toFixed(1) : totalScore}</h2>
            </div>
          </div>
        </div>
        {
          pointSetur ? (
            <div className="div_card_setur_pontuation div_card_max_pontuation">
              <div className="div_align_card_max">
                <div className="div_img_card_max">
                  <img src={SeturPontuationIcon} />
                </div>
                <div className="div_info_card_max">
                  <span>PONTUAÇÃO SETUR</span>
                  <h2 className='title_score_calculator'>{pointSetur || <SpinButton />}</h2>
                </div>
              </div>
            </div>
          ) : null
        }
        <div className="div_card_max_pontuation">
          <div className="div_align_card_max">
            <div className="div_img_card_max">
              <img src={MaxPontuationIcon} />
            </div>
            <div className="div_info_card_max">
              <span>PONTUAÇÃO MÁXIMA</span>
              <h2 className='title_score_calculator'>{maxScore || <SpinButton />}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className='div_align_cards_score'>
        <CardScoreCalculator 
          title={'1 - Dimensão de Fluxo Turístico'}
          data={attracScore}
          dataRankSum={dataRankSum['attr']}
          setScore={setAttracScore}
          functionUpdateScore={calculateSectionScore}
          keyScore={'attr'}
          updateValueCity={updateValueCity}
        />
        <CardScoreCalculator
          title={'2 - Dimensão de Atrativos Turísticos'}
          data={cityScore}
          dataRankSum={dataRankSum['city']}
          setScore={setCityScore}
          functionUpdateScore={calculateSectionScore}
          keyScore={'city'}
          updateValueCity={updateValueCity}
        />
        <CardScoreCalculator
          title={'3 - Dimensão de infraestrutura, equipamentos e serviços turísticos'}
          data={demandScore}
          dataRankSum={dataRankSum['demand']}
          setScore={setDemandScore}
          functionUpdateScore={calculateSectionScore}
          keyScore={'demand'}
          updateValueCity={updateValueCity}
        />
        <CardScoreCalculator
          title={'4 - Dimensão de Turismo, Sustentabilidade, Acessibilidade e Legislação'}
          data={comturScore}
          dataRankSum={dataRankSum['comtur']}
          setScore={setComturScore}
          functionUpdateScore={calculateSectionScore}
          keyScore={'comtur'}
          updateValueCity={updateValueCity}
        /> 
      </div>
    </div>
  );
}