import React, { useContext, useEffect, useState } from 'react';

// Firebase Import
import firebase from '../../config/Firebase';

// Assets imports
import ShowMoreIcon from '../../assets/imgs/showmore_icon.svg';
import ArrowRight from '../../assets/imgs/left-arrow-white.png';
import ArrowLeft from '../../assets/imgs/right-arrow-white.png';

// eslint-disable-next-line import/no-cycle
import { MyContext } from '../../component/SidebarAdmin';

// CSS Import
import '../../assets/css/RankingComponents/rankingCities.css';

// Components Imports
import { RodapeAdmin } from '../../component/RodapeAdmin';
import Loading from '../../component/Loading';
import TitleEditor from '../../component/FormComponents/TitleEditor';
// eslint-disable-next-line import/no-cycle
import RankingCalculator from './RankingCalculator';
import RankingCalculatorSP from './RankingCalculatorSP';

export default function RankingCities() {
  const { city, cityAdmin, role } = useContext(MyContext);
  const [cities, setCities] = useState();
  const [indexFilter, setIndexFilter] = useState(0);
  const [citySelected, setCitySelected] = useState();
  const [rankingPopup, setRankingPopup] = useState(false);
  const [filter, setFilter] = useState('pontosTotal');
  const [order, setOrder] = useState(-1);
  const [loading, setLoading] = useState(false);

  async function handleInfoCities() {
    let docs;

    if (role === 'cityAdmin' && cityAdmin)
      docs = await firebase.firestore()
        .collection('Cities')
        .where('name', 'in', cityAdmin)
        .get();
    else
      docs = await firebase.firestore()
        .collection('Cities')
        .get();

    const citiesDocs = [];
    const rankingDocs = [];

    docs.docs.map((city) => {
      if (!city.data().regiao)
        citiesDocs.push(city.data());
    });

    const newCities = mergeSort(citiesDocs);

    newCities.map((city, index) => {
      rankingDocs.push({ ...city, rankingIndex: index + 1 });
    });

    setCities(rankingDocs);
  }

  function handleRemoveIndexFilter() {
    if (indexFilter !== 0)
      setIndexFilter((curr) => curr - 20);
  }

  function handleAddIndexFilter() {
    if ((cities.length - indexFilter) >= 20)
      setIndexFilter((curr) => curr + 20);
  }

  function handleReviewRankCity(city) {
    setCitySelected(city);
    setRankingPopup(true);
  }

  function merge(left, right) {
    const sortedArr = [];
    while (left.length && right.length) {
      const leftValue = left[0][filter] || 0;
      const rightValue = right[0][filter] || 0;
      const compare = ((order > 0) ? (leftValue < rightValue) : (leftValue > rightValue));

      if (compare) {
        sortedArr.push(left.shift());
      } else {
        sortedArr.push(right.shift());
      }
    }
    return [...sortedArr, ...left, ...right];
  }

  function mergeSort(arr) {
    if (arr.length <= 1) return arr;

    const mid = Math.floor(arr.length / 2);

    const left = mergeSort(arr.slice(0, mid));
    const right = mergeSort(arr.slice(mid));

    return merge(left, right);
  }

  function handleFilter(filter, order) {
    setFilter(filter);
    setOrder(order);
  }

  useEffect(() => {
    if (cities) {
      const newCities = mergeSort(cities);
      setCities(newCities);
    }  
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      handleInfoCities();
    }, 150);
    setLoading(false);
  }, [city]);

  useEffect(() => {
    if (!rankingPopup)
      handleInfoCities();
  }, [rankingPopup]);

  if (!cities || loading)
    return <Loading />;

  console.log(citySelected);
  return (
    <>
      {
        rankingPopup ? <RankingCalculatorSP cities={cities} cityManager={citySelected} setRankingPopup={setRankingPopup} /> : (
          <div className='RankingCities'>
            <TitleEditor type={'Ranqueamento Geral das Cidades'} subTitle={'Ranking por uma lista das cidades disponiveis.'} />
            <div className='div_align_cards_score'>
              <div className='div_table_score_calculator div_table_score_ranking_cities' id={'div_table_score_calculator_cities'} >
                <table className='table_score_calculator table_score_ranking_cities' id={'table_score_calculator_cities'}>
                  <thead>
                    <tr className='row_table_score_calculator title_rows_table_score'>
                      <th className='th_ranking_cities th_ranking_cities_more' onClick={() => handleFilter('pontosTotal', -1)}>Ranking</th>
                      <th className='th_ranking_cities th_ranking_cities_descr' onClick={() => handleFilter('nameOrdem', 1)}>Nome</th>
                      <th className='th_ranking_cities th_ranking_cities_info' onClick={() => handleFilter('pontosInventario', -1)}>Pontos Inventário</th>
                      <th className='th_ranking_cities th_ranking_cities_info' onClick={() => handleFilter('pontosCidade', -1)}>Pontos Cidade</th>
                      <th className='th_ranking_cities th_ranking_cities_info' onClick={() => handleFilter('pontosComtur', -1)}>Pontos Comtur</th>
                      <th className='th_ranking_cities th_ranking_cities_info' onClick={() => handleFilter('pontosEstudoDemanda', -1)}>Pontos Estudo de Demanda</th>
                      <th className='th_ranking_cities th_ranking_cities_info' onClick={() => handleFilter('pontosTotal', -1)}>Total</th>
                      <th className='th_ranking_cities th_ranking_cities_more'>Ver Detalhes</th>
                    </tr>
                  </thead>
                  {
                    cities.map((city, index) => {
                      if (index >= indexFilter && index < indexFilter + 20) {
                        return (
                          <tbody key={index}>
                            <tr className={`row_table_score_calculator sub_rows_table_score ${(index % 2) ? '' : 'bg_gray'}`}>
                              <td className='info_table_ranking_cities th_ranking_cities_more'>{city.rankingIndex}</td>
                              <td className='info_table_ranking_cities th_ranking_cities_descr td_ranking_cities_descr'>{city.name}</td>
                              <td className='info_table_ranking_cities th_ranking_cities_info'>{city.pontosInventario || 0}</td>
                              <td className='info_table_ranking_cities th_ranking_cities_info'>{city.pontosCidade || 0}</td>
                              <td className='info_table_ranking_cities th_ranking_cities_info'>{city.pontosComtur || 0}</td>
                              <td className='info_table_ranking_cities th_ranking_cities_info'>{city.pontosEstudoDemanda || 0}</td>
                              <td className='info_table_ranking_cities th_ranking_cities_info'>{city.pontosTotal || 0}</td>
                              <td className='info_table_ranking_cities th_ranking_cities_more'>
                                <button onClick={() => handleReviewRankCity(city.name)} className='btn_show_more_ranking_cities'>
                                  <img src={ShowMoreIcon} alt='Icone para visualizar mais detalhadamente' className='img_btn_show_more_ranking_cities'/>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        );
                      }
                    })
                  }
                </table>
                <div className='div_aling_filter_index'>
                  <div className='div_filter_index'>
                    <button onClick={() => handleRemoveIndexFilter()} className='btn_index_filter'>
                      <img src={ArrowLeft} alt='Icone de uma seta para esquerta' className='img_btn_index_filter' />
                    </button>
                    <button onClick={() => handleAddIndexFilter()} className='btn_index_filter'>
                      <img src={ArrowRight} alt='Icone de uma seta para direita' className='img_btn_index_filter' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      </>
  );
}